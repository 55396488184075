jQuery(document).ready( function () {



  jQuery(".burger-menu").click(function() {
      jQuery(".header-menu-inner").toggleClass('open');
  });

  jQuery(".close-btn").click(function() {
      jQuery(".header-menu-inner").toggleClass('open');
  });

  jQuery("#room-book-toggle").click(function() {
      jQuery(".room-book-extra").slideToggle('fast');
  });

  // Add class to header once scrolled past X pixels
  function headerClass() {
      var scroll = jQuery(window).scrollTop();
      if (scroll <= 0) {
          jQuery("header").removeClass("scrolled");
      }else {
          jQuery("header").addClass("scrolled");
      }
  }

  jQuery(window).resize(function() {
      headerClass();
  });

  jQuery(window).scroll(function() {
      headerClass();
  });

  headerClass();


  // Main slider

  jQuery(".h-menu-slider").owlCarousel({
      nav: true,
      items: 1,
      loop: true,
      dots: true,
      autoplay: true,
      smartSpeed: 1000,
      addClassActive: true,
      navText : ["←","→"],
      loop: jQuery('.h-menu-slider').children().length > 1,
  });

  jQuery(".slides").owlCarousel({
      nav: false,
      items: 1,
      loop: true,
      dots: true,
      autoplay: true,
      smartSpeed: 1400,
      addClassActive: true,
      mouseDrag: false,
      touchDrag: false,
      animateOut: 'fadeOut', 
      loop: jQuery('.slides').children().length > 1,
      onTranslate: function (event) {
        jQuery('.overlay-content').removeClass('aos-init').removeClass('aos-animate');
      },
      onTranslated: function (event) {
        jQuery('.overlay-content').addClass('aos-init').addClass('aos-animate');
      }
  });
    
  // Scroll down button (Twice because of the header changing height)
  jQuery('#scrolldown').click(function() {
    var scrolldownTarget = jQuery('#scrolldown-target');
      jQuery('html, body').animate({scrollTop: jQuery( scrolldownTarget ).offset().top * 0.9 }, {
        duration: "300",
      });
  });

});
