var startDate = moment();
var endDate = moment().add(1, "days");
//endDate.setDate((startDate.getDate())+1);

var endPicked = false; //Whether they have manually picked an end date.

var baseUrl = "https://uk1.roomlynx.net/rezrooms2/loadOBMApplication.action?siteId=ROWTON745&request_locale=en&chainAction=newAvailabilitySearch";
var offerCodeUrl= "&offerCode=";
//"&arrival=21/11/2017&numberOfNights=1&numberOfPersons=2&numberOfChildren=0&offerCode="

var nights = 1;
var adults = 2;
var children = 0;

jQuery(document).ready(function() {
   

   var arrival = startDate.format("DD/MM/YYYY");
   jQuery("#start-datepicker").attr("value",arrival);
   jQuery("#submit-button").attr("value", arrival);
   jQuery("#end-datepicker").attr("value",(endDate.format("DD/MM/YYYY")));
    
   jQuery("#nights").attr("value",nights);
   jQuery("#adults").attr("value",adults);
   jQuery("#children").attr("value",children);

   var startDateSelect = function(date) {
      startDate = moment(date);
      console.log(startDate);
      endDate = moment(startDate).add(1, "days");
      nights = Math.abs(moment(startDate).diff(moment(endDate),"days"));
      jQuery("#nights").attr("value",nights);
      
      var arrival = startDate.format("DD/MM/YYYY");
      jQuery("#start-datepicker").attr("value", arrival);
      jQuery("#submit-button").attr("value", arrival); 
      if (!endPicked) {
        jQuery("#end-datepicker").attr("value",(endDate.format("DD/MM/YYYY"))); //Unless set otherwise, keep one day after start date.
        jQuery('#end-datepicker').next().find('.month').html(endDate.format('MMMM'));
        jQuery('#end-datepicker').next().find('.date').html(endDate.format('D'));
      }

      jQuery('#start-datepicker').next().find('.month').html(startDate.format('MMMM'));
      jQuery('#start-datepicker').next().find('.date').html(startDate.format('D'));
   };

   var endDateSelect = function(date) {
      endDate = moment(date);
      nights = Math.abs(moment(startDate).diff(moment(endDate),"days"));
      jQuery("#nights").attr("value",nights);
      
      jQuery("#end-datepicker").attr("value",(endDate.format("DD/MM/YYYY")));
      endPicked = true;

      jQuery('#end-datepicker').next().find('.month').html(endDate.format('MMMM'));
      jQuery('#end-datepicker').next().find('.date').html(endDate.format('D'));
   };

   jQuery("#start-datepicker").datepicker({
        onSelect: function (date) { 
          startDateSelect(date);
        },
        minDate: "+0D",
        showOn: "button",
        buttonText: "<span class=\"month\"></span><span class=\"date\"></span><span class=\"button-text\">Arrival</span>"
    });

    jQuery("#end-datepicker").datepicker({
        onSelect: function (date) { 
          endDateSelect(date);
        },
        minDate: "+1D",
        showOn: "button",
        buttonText: "<span class=\"month\"></span><span class=\"date\"></span><span class=\"button-text\">Depart</span>"
    });

    // Setting the default date
    jQuery('#start-datepicker').datepicker('setDate', '+0D');
    startDateSelect(jQuery('#start-datepicker').val());
   var mobileDetector = new MobileDetect(navigator.userAgent);
   if (!mobileDetector.mobile() || mobileDetector.mobile().length == 0) {
     document.getElementById('room-book-toggle').click();
   } 
});

